
import request from "@/utils/request";


// 经营报表
export function getReportApi(data) {
  return request({
    url: `/wxapp/mer/report`,
    method: 'post',
    data
  })
}