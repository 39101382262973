<template>  
  <div class="container">
    <div class="report-box">
      <div class="date-box rowbetween">
        <div class="date-type row">
          <!-- <span class="type active">日报</span>
          <div class="line"></div>
          <span class="type">周报</span>
          <div class="line"></div>
          <span class="type">月报</span> -->
        </div>
        <div class="data-area row" @click="showTime = true">
          <span class="data-value">{{timeName}}</span>
          <span class="more"></span>
          <!-- <img src="../assets/images/arrow3.png" class="arrow3-img" mode="aspectFill" /> -->
        </div>
        <van-popup v-model:show="showTime" round position="bottom">
          <van-picker
            :columns="timeList"
            @cancel="showTime = false"
            @confirm="onConfirmTime"
          />
        </van-popup>  
      </div>
      <div class="total-box center">
        <span class="receipts">实收金额</span>
        <div class="money-total row">
          <span class="unit">￥</span>
          <span>{{dataList!=null?dataList.settAmount:0}}</span>
        </div>
        <span class="compare" v-if="dataList!=null">
          <block v-if="type==0">与上一日</block>
          <block v-if="type==1">与上周</block>
          <block v-if="type==2">与上月</block>
          <block v-if="type==3">与上月</block>
          <block v-if="dataList.settAmount>dataList.lastSettAmount">增加￥{{subNumb(dataList.settAmount,dataList.lastSettAmount)}}</block>
          <block v-if="dataList.settAmount<dataList.lastSettAmount">减少￥{{-subNumb(dataList.settAmount,dataList.lastSettAmount)}}</block>
          <block v-if="dataList.settAmount==dataList.lastSettAmount">持平</block>          
          <!-- <block v-if="dataList!=null && dataList.settAmount!=dataList.lastSettAmount">{{dataList.settAmount-dataList.lastSettAmount}}</block>
          <block v-else>持平</block> -->
        </span>
      </div>
      <div class="pay-type row">
        <div class="type-item center">
          <img src="../assets/images/wx.png" class="type-img" mode="aspectFill" />
          <span class="money-value">{{dataList!=null?dataList.wxSettAmount:0}}</span>
        </div>
        <div class="type-item center">
          <img src="../assets/images/zfb.png" class="type-img" mode="aspectFill" />
          <span class="money-value">{{dataList!=null?dataList.zfbSettAmount:0}}</span>
        </div>
        <div class="type-item center">
          <img src="../assets/images/ysf.png" class="type-img" mode="aspectFill" />
          <span class="money-value">{{dataList!=null?dataList.ysfSettAmount:0}}</span>
        </div>
        <div class="type-item center">
          <img src="../assets/images/yzf.png" class="type-img" mode="aspectFill" />
          <span class="money-value">{{dataList!=null?dataList.yzfSettAmount:0}}</span>
        </div>
      </div>
      <div class="summary-box">
        <div class="summary-item column">
          <span class="summary-title">总人数</span>
          <span class="summary-num">0</span>
        </div>
        <div class="row-line"></div>
        <div class="summary-item column">
          <span class="summary-title">总笔数</span>
          <span class="summary-num">{{dataList!=null?dataList.successCount:0}}</span>
        </div>
        <div class="row-line"></div>
        <div class="summary-item column">
          <span class="summary-title">单笔均价</span>
          <span class="summary-num">￥{{dataList!=null?dataList.averagePrice:0}}</span>
        </div>
      </div>
      <div class="summary-box">
        <div class="summary-item column">
          <span class="summary-title">退款金额</span>
          <span class="summary-num">￥{{dataList!=null?dataList.refundAmount:0}}</span>
        </div>
        <div class="row-line"></div>
        <div class="summary-item column">
          <span class="summary-title">退款笔数</span>
          <span class="summary-num">{{dataList!=null?dataList.refundCount:0}}</span>
        </div>
        <div class="row-line"></div>
      </div>
    </div>
    
    <div class="trade-report">
      <div class="report-title row">
        <div class="r-line"></div>
        交易报表
      </div>
      <div class="table">
        <div class="thead">
          <div class="tr">
            <div class="th w24">日期</div>
            <div class="th w19">订单金额</div>
            <div class="th w19">退款金额</div>
            <div class="th w19">手续费</div>
            <div class="th w19">结算金额</div>
          </div>
        </div>
        <div class="tbody" v-if="dataList!=null && dataList.dailyList!=null && dataList.dailyList.length>0">
          <div class="tr" v-for="(item,index) in dataList.dailyList" :key="index">
            <div class="td w24">{{item.date}}</div>
            <div class="td w19">{{item.successOrderAmount}}</div>
            <div class="td w19">{{item.refundAmount}}</div>
            <div class="td w19">{{item.feeAmount}}</div>
            <div class="td w19">{{item.settAmount}}</div>
          </div>
        </div>
        <div class="tbody" v-else>
          <div class="tr">
            <div class="td w100">暂无交易记录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, onMounted, toRefs, nextTick } from 'vue'
  import { getReportApi } from '@/api/capitalReport'
  import { Toast } from 'vant'
  import { useStore  } from 'vuex'
  export default {
    name: 'capitalreport',
    data() {
      return {
        showTime:false,
        timeName:'当月收入',
        type: 2,  //0：昨日 1：近7日 2：当月收入 3：上月收入
        index:0,
        timeList:[
          {
            value:2,
            text:'当月收入'
          },
          {
            value:1,
            text:'近7日'
          },
          {
            value:0,
            text:'昨日'
          },
          {
            value:3,
            text:'上月收入'
          },
        ],
        dataList: null, //初始数据
      };
    },
    created(){
      this.getReport();  //获取经营报表汇总
    },
    methods: {
      //获取经营报表汇总
      getReport(){
        const that = this;
        const param = {
          "type": that.type
        }
        getReportApi(param).then(res => {
          if (res && res.data) {
            let data = res.data;
            that.dataList = data;
          }   
        });        
      },
      //切换时间
      onConfirmTime(value){
        if(value){
          this.type = value.value;
          this.timeName = value.text;
          this.getReport();
        }
        this.showTime = false;
      },
      subNumb(arg1, arg2) {
        var r1, r2, m, n
        try {
          r1 = arg1.toString().split('.')[1].length
        } catch (e) {
          r1 = 0
        }
        try {
          r2 = arg2.toString().split('.')[1].length
        } catch (e) {
          r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2)) // last modify by deeka //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2
        return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
      }
    }
  }
</script>

<style lang="less" scoped >
  @import '../assets/style/mixin';
    
  .container {
    background-color: #efefef;
    font-size: 12px;
    /* padding-bottom: 20px; */
  }
  .report-box{
    padding-top: 15px;
    background-color: #ffffff;
  }
  .date-box{
    padding: 9px 15px 0 15px;
  }
  .date-type{
    height: 30px;
    background: #F7F7F7;
    border-radius: 3px;
    align-items: center;
    justify-content: space-between;
  }
  .date-type .type{
    font-size: 15px;
    font-weight: bold;
    color: #999999;
    padding: 0 17.5px;
  }
  .date-type .type.active{
    color: #FA3474;
  }
  .date-type .line{
    width: 1px;
    height: 15px;
    background: #999999;
  }
  .data-area{
    align-items: center;
  }
  .data-value{
    font-size: 14px;
    font-weight: 400;
    color: #FA3474;
  }
  .data-area .more{
    flex: none;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #FA3474;
    border-right: 1px solid #FA3474;
    transform: rotate(45deg);
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .arrow3-img{
    width: 9px;
    height: 5px;
    margin-left: 8px;
  }
  .total-box{
    padding: 22px 0 47px;
  }
  .receipts{
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
  .money-total{
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    margin-top: 17px;
    margin-bottom: 13px;
  }
  .unit{
    font-size: 18px;
    margin-top: 5px;
    margin-right: 5px;
  }
  .compare{
    font-size: 12px;
    font-weight: 400;
    color: #FA3474;
  }
  .pay-type{
    margin-bottom: 47px;
    align-items: center;
    justify-content: space-around;
  }
  .type-img{
    width: 25px;
    height: 25px;
  }
  .money-value{
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    margin-top: 13px;
  }

  .summary-box{
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
  }
  .summary-item{
    
  }
  .summary-item{
    width: 125px;
    /* height: 76px; */
    justify-content: space-between;
    align-items: center;
  }
  .summary-title{
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-top: -3px;
  }
  .summary-num{
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    margin-top: 8px;
  }
  .row-line{
    width: 1px;
    height: 38px;
    background: #999999;
  }
  /* 交易报表 */
  .trade-report{
    background-color: #ffffff;
    margin-top: 15px;
    padding-bottom: env(safe-area-inset-bottom);
  }
  .report-title{
    height: 45px;
    padding: 0 15px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    align-items: center;
  }
  .r-line{
    width: 3px;
    height: 14px;
    background: #FA3474;
    border-radius: 1.5px;
    margin-right: 7px;
  }

  /* table */

  .table {
    background: #fff;
  }
  .thead{
    background: #FA3474;
  }
  .table .tr {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .table .tr .th {  
    min-height: 20px;
    color: #ffffff;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 5px 2px;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
  }

  .table .tr .td {
    min-height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: #333;
    padding: 5px 2px;
    align-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: #333333;
  }
  .table .tbody .tr:nth-of-type(even){
    background-color: #fa34731f;
    
  }
  .w24{
    width: 24%;
  }
  .w19{
    width: 19%;
  }
  .w100{
    width: 100%;
  }


</style>